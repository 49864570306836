<template>
  <jf-layout>
    <template slot="head">
      <el-form class="form" :inline="true" size="small">

        <el-form-item label="任务月份">
          <el-date-picker v-model="params.taskMonth" type="month" :clearable="false" value-format="yyyy-MM"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="hint">
        <div>已选中<span class="c-warning">{{selectList.length}}</span>条</div>
        <div>
          <el-button size="mini" type="danger" icon="el-icon-delete" v-if="isAuth('Slideshow:tbslideshow:delete')" :disabled="!selectList.length" @click="remove()">删除</el-button>
        </div>
      </div> -->
    </template>
    <el-table slot="body" v-loading="loading" border :data="list" ref="multipleTable" @selection-change="getSelect">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" prop="accountType" label="账户类型">
        <template slot-scope="{row}">
          <span v-if="row.accountType===0">企业账户</span>
          <span v-if="row.accountType===1">个人账户</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="tenantName" label="组织名称" />
      <el-table-column align="center" prop="taskMonth" label="任务月份" />
      <el-table-column align="center" prop="" label="用户类型">
        <template slot-scope="{row}">
          <span v-if="row.userType===0">付费</span>
          <span v-if="row.userType===1">试用</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="settleSuccessCount" label="结算成功统计" />
      <el-table-column align="center" prop="settleFailCount" label="结算失败统计" />
      <el-table-column align="center" label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" @click="goDetail(row)">详情</el-button>
          <el-button size="mini" @click="checkout(row)">重新结算</el-button>
        </template>
      </el-table-column>
    </el-table>
    <jf-page slot="foot" @change="getList()" :total="total" :page.sync="params.page" :limit.sync="params.limit" />

  </jf-layout>
</template>

<script>

export default {

  data() {
    return {
      show: false,
      activeId: null,
      total: 0,
      params: {
        page: 1,
        limit: 10,
        taskMonth: null,

      },
      list: [],
      loading: false,
      selectList: [],
    };
  },
  created() {
    this.params.taskMonth = this.getLastMonth();
    this.getList();
  },
  methods: {
    goDetail(row) {
      this.$router.push({
        path: '/setup-taskMonitor-list',
        query: {
          tenantCode: row.tenantCode,
          taskMonth: this.params.taskMonth,
          tenantName: row.tenantName
        }
      })
    },
    /* 重新结算 */
    checkout(row) {
      let data = {
        tenantCode: row.tenantCode,
        taskMonth: this.params.taskMonth
      }
      this.$confirm("确定重新结算?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {

          this.$del("/platform-config/taskrecordmembermonitoring/tenant/settle", data).then(res => {
            if (res && res.code == 0) {
              this.$message.success("结算成功！");
              this.getList();
            }
          })


        })
        .catch(() => { });
    },
    search() {
      this.page = 1;
      this.getList();
    },
    getLastMonth() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth();
      if (month === 0) {
        month = 12;
        year = year - 1;
      }
      month = month < 10 ? "0" + month : month;
      return year + "-" + month;
    },
    // 获取列表
    getList() {
      this.loading = true;
      this.$get(
        "/platform-config/taskrecordmembermonitoring/page",
        this.params
      ).then(res => {
        if (res && res.code == 0) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      }).finally(() => {
        this.loading = false;
      })

    },
    // 选择table
    getSelect(list) {
      this.selectList = list;
    },

  }

};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__label {
  line-height: 40px;
}
.hint {
  display: flex;
  justify-content: space-between;
  background: #fff4e3;
  border-radius: 5px;
  padding: 0 17px;
  margin-top: 15px;
}
</style>
